(function ($, window, document) {
  'use strict';
  $(function () {
    //##Variables
    var $body = $('body'),
      $window = $(window),
      $doc = $(document),
      defaultEasing = [0.4, 0.0, 0.2, 1];
    //End Variables

    $('.efc-slider').owlCarousel({
      stagePadding: 50,
      loop: false,
      margin: 20,
      center: false,
      nav: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 4,
        },
      },
    });

    $(function () {
      $(window).scroll(function () {
        var top_offset = $(window).scrollTop();
        if (top_offset == 0) {
          $('.main_header').removeClass('fixed-top fade-in');
        } else {
          $('.main_header').addClass('fixed-top fade-in');
        }
      });
    });
  });
})(window.jQuery, window, document);

jQuery(document).on('click', '.mobile-hamburger', function () {
  jQuery('.mobile-nav').toggleClass('menu-open');
});

var EtherumDollarPrice;
var dataArray = new Array();
var startEthUpgradePointCost = 0.025;
var startElfNftPrice = 0.025;
$('#value-selected').html(startElfNftPrice);

$(document).on('change', '#chi', function (e) {
  e.preventDefault();
  var value = parseInt($(this).val());
  let dataArrayElement = dataArray.filter(function (e) {
    return e.id == value;
  });
  var objectData = dataArrayElement[0];
  $('#value-selected').html(objectData.elfNftPrice);
  $('#total-value').html('$' + objectData.usdPerElf);
  $('#selected-image').attr('src', objectData.src);
});

$.ajax({
  url: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
  type: 'GET',
  success: function (data) {
    EtherumDollarPrice = data['USD'];
    calculateData(data['USD']);
    initSelectBox();
    $('#total-value').html('$' + parseFloat(startElfNftPrice * EtherumDollarPrice).toFixed(3));
  },
});

function increaseTooltip() {
  calculateData(EtherumDollarPrice);
  setTimeout(increaseTooltip, 60000);
}
increaseTooltip();
selectchoices = [];
function initSelectBox() {
  for (let index = 3; index <= 54; index++) {
    selectchoices.push({ value: '' + index + '', label: '' + index + '', id: index });
  }
  new Choices('.select-select', {
    silent: false,
    searchEnabled: false,
    choices: selectchoices,
    itemSelectText: '',
    classNames: {
      containerOuter: 'choices select-choices',
    },
  });
}

function calculateData(priceOfEtherum) {
  let newDataArray = [];

  const date1 = new Date();
  const date2 = new Date('11/11/2022');
  const diffTime = Math.abs(date2 - date1);
  const diffMinutes = Math.ceil(diffTime / (1000 * 60));
  const averageMinutes = 1 - diffMinutes / 525600;
  const legacyFactor = 1.05 + averageMinutes * (1.2 - 1.05);
  $('#hover-price-box').html('Total: ' + legacyFactor);
  const legacyFactorString = legacyFactor.toString();
  const legacyResult = Number(legacyFactorString.slice(0, 6));
  $('#factor-price').html(legacyResult);
  for (let index = 3; index <= 54; index++) {
    if (newDataArray.length == 0) {
      let dataObject = {};
      dataObject.id = index;
      dataObject.src = '/dist/images/' + index + '.jpg';
      dataObject.ethUpgradePointCost = startEthUpgradePointCost;
      dataObject.elfNftPrice = startElfNftPrice;
      dataObject.usdPerElf = parseFloat((startElfNftPrice * priceOfEtherum).toFixed(3));
      newDataArray.push(dataObject);
    } else {
      let dataObject = {};
      dataObject.id = index;
      dataObject.label = index;
      dataObject.value = index;
      dataObject.src = '/dist/images/' + index + '.jpg';
      let ethLastValue = parseFloat((newDataArray[newDataArray.length - 1].ethUpgradePointCost * legacyFactor).toFixed(3));
      let elfNftLastValue = parseFloat((newDataArray[newDataArray.length - 1].elfNftPrice + ethLastValue).toFixed(3));
      dataObject.ethUpgradePointCost = ethLastValue;
      dataObject.elfNftPrice = elfNftLastValue;
      dataObject.usdPerElf = parseFloat((elfNftLastValue * priceOfEtherum).toFixed(3));
      newDataArray.push(dataObject);
    }
  }
  dataArray = newDataArray;
}
